header {
  background-image: url('../../images/bg_waterlilly.jpg');
  background-size: cover;
  min-height: 100vh;
}

a {
  color:white ;
  text-decoration: none;
}

.navigation {
  margin: 0 auto;
  padding: 1rem;
  width: 1440px;
  max-width: 100%;
}

.pre-nav {
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.logo {
  color: lightpink;
  font-weight: bold;
  padding: 0.7rem;
}

.navbar-nav {
  text-align: center;
}

.nav-link {
  background-color: lightcoral;
  color: white!important;
  opacity: 0.8;
}

nav a.active {
  background-color: white;
  color: lightpink;
  font-weight: bold;
}

nav a:hover {
  opacity: 0.9;
  background-color: white;
  color: lightcoral!important;
  text-decoration: none;
}

.main-image {
  display: flex;
  justify-content: center;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  margin: 2rem;
}

.main-image img {
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
}

.content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width:100%;
}

.content img {
  border-radius: 5px;
  border: none;
  
}

a.highlight {
  background-color: white;
  border-radius: 5px;
  color:  lightcoral;
  font-weight: bold;
  padding: 0.5rem;
  display: inline-block;
}

.card-link+.card-link {
  margin-left: 0;
}

.event {
  background-color: lightpink;
  border-radius: 5px;
  display: inline-block;
  margin: 1rem;
  padding: 1rem;
  opacity: 0.85;
  max-width: 400px;
  width: 90%;
}

.taller {
  max-width: 90%;
}

.footer {
    height: 60px;
    background-color: lightcoral;
    font-size: calc(6px + 2vmin);
    padding-top: 0px;
    padding-bottom: 0px;
    color:white;
}

a.social{
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block; 
  color: #4267B2;
}

a.social :hover{
  transform: translateY(-2px);
}


